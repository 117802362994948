<template>
  <div class="my_list_page">
    <div class="search-main">
      <div class="search-input">
        <el-input placeholder="请输入企业名称" prefix-icon="el-icon-search" size="small" clearable v-model="searchVal"
          @keyup.enter.native="toSearch" @input="toSearch" />
      </div>
      <div class="monitor-text">
        已监控：<span>{{ monitoredEnterpriseSum }}</span>家
        &nbsp;&nbsp;&nbsp;
        还可监控：<span>{{ Number(monitorRightsSum) - Number(monitoredEnterpriseSum) }}</span>家
      </div>
    </div>
    <div class="content">
      <div class="my_table">
        <el-table :data="listData" style="width: 100%" border class="custom_my_table" v-loading="pageLoading">
          <el-table-column align="left" prop="enterpriseName" label="来源" width="655">
            <template slot-scope="scope">
              <span class="row-name" @click="clickMore(scope.row.enterpriseInfoId)">{{ scope.row.enterpriseName
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" fixed="right" label="操作" width="278">
            <template slot-scope="scope">
              <div class="operation">
                <template v-if="scope.row.ifMonitor === 1">
                  <div class="monitor-status monitor-status-blue" @click="clickCancel(scope.row)">
                    <i class="iconfont icon-yijiankong"></i>
                    已监控
                  </div>
                </template>
                <template v-else>
                  <div class="monitor-status monitor-status-grey">
                    <i class="iconfont icon-weijiankong"></i>
                    未监控
                  </div>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="my_pagination">
          <el-pagination background @size-change="pagination.handleSizeChange"
            @current-change="pagination.handleCurrentChange" :current-page="pagination.pageNo"
            :page-sizes="[10, 20, 50, 100]" :page-size="pagination.pageSize"
            layout="total, sizes, jumper, prev, pager, next" :total="pagination.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as api from '@/api'
import { debounce } from 'lodash'
let updateTimer = null
export default {
  data() {
    return {
      searchVal: '',
      pagination: {
        pageNo: 1,
        pageSize: 20,
        total: 0,
        handleSizeChange: '',
        handleCurrentChange: '',
      },
      listData: [],
      options: [
        {
          label: '后台导入',
          value: 'default',
        },
        {
          label: '活动名单',
          value: 'activity',
        },
        {
          label: '筛选导出',
          value: 'advancedSearchExport',
        },
        {
          label: '邮件推送',
          value: 'product',
        },
        {
          label: '管理员分配',
          value: 'adminPushImport',
        }
      ],
      searchObj: {
        time: [],
        type: '',
      },
      pageLoading: false,
      monitorRightsSum: '',
      monitoredEnterpriseSum: '',
    }
  },
  methods: {
    toSearch: debounce(function () {
      this.pagination.pageNo = 1
      this.pagination.pageSize = 20
      this.getListData()
    }, 300),
    getListData() {
      this.pageLoading = true
      let params = {
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize,
        enterpriseName: this.searchVal
      }
      api.riskMonitor.searchMonitorPateEnterpriseUser(params).then(({ success, data }) => {
        if (success) {
          this.listData = data.data.monitorSearchPateEnterpriseUserVOPage.records
          this.pagination.total = Number(data.data.monitorSearchPateEnterpriseUserVOPage.total)
          this.monitorRightsSum = data.data.monitorRightsSum
          this.monitoredEnterpriseSum = data.data.monitoredEnterpriseSum
        }
        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    },
    clickCancel(row) {
      this.$confirm('确认取消监控该企业吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let params = {
          enterpriseIds: [row.enterpriseInfoId]
        }
        api.riskMonitor.deleteMonitorPateEnterpriseUser(params).then(({ success }) => {
          if (success) {
            this.$message.success('取消监控成功')
            this.getListData()
          }
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
      });
    },
    clickMore(enterpriseId) {
      this.$router.push({
        path: '/accountCenter/msgCenter/enterpriseAllMsg',
        query: {
          enterpriseId
        }
      })
    },
  },
  mounted() {
    this.pagination.handleSizeChange = (e) => {
      this.pagination.pageSize = e
      this.getListData()
    }
    this.pagination.handleCurrentChange = (e) => {
      this.pagination.pageNo = e
      this.getListData()
    }
    this.getListData()
  },
  computed: {
    ...mapState(['userInfo'])
  },
}
</script>

<style lang="scss" scoped>
.my_list_page {
  .content {
    padding: 0px Translate(20px);
    border-radius: 5px;

    .page_title {
      height: Translate(76px);
      line-height: Translate(76px);
      font-size: Translate(18px);
      color: #333333;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
    }

    .my_table {
      .row-name {
        cursor: pointer;

        &:hover {
          color: #4088EB;
        }
      }

      .my_pagination {
        display: flex;
        justify-content: flex-end;
        margin: Translate(20px) 0 Translate(40px) 0;
      }

      .dots-icon {
        position: relative;
        top: Translate(-2px);
        margin-right: Translate(2px);
        background: #52c41b;
        width: Translate(6px);
        height: Translate(6px);
        border-radius: 50%;
        display: inline-block;
      }

      .error-dots {
        background: #ff8321;
      }

      .operation {
        color: #4088eb;
        display: flex;
        justify-content: center;

        span {
          cursor: pointer;
        }

        .mr-10 {
          margin-right: Translate(10px);
        }
      }
    }
  }


  .search-main {
    padding: 0px Translate(20px);
    height: Translate(80px);
    // border-bottom: Translate(16px) solid #f5f6f9;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .monitor-text {
      font-size: Translate(14px);
      color: #999999;

      span {
        color: #FF8321;
      }
    }
  }

  .monitor-status {
    width: Translate(82px);
    height: Translate(28px);
    border-radius: Translate(4px);
    font-size: Translate(12px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &-blue {
      color: #4088EB;
      border: 1px solid #4088EB;
    }

    &-grey {
      color: #333333;
      border: 1px solid #D9D9D9;
    }

    .iconfont {
      font-size: Translate(12px);
      margin-right: Translate(4px);
    }
  }
}
</style>